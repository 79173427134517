body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.25;
  min-height: 4480px;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
//     monospace;
// }

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  color: #61dafb;
  pointer-events: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.healthpathways {
  max-width:1280px;
  border-left:1px solid rgba(0,0,0,0.1);
  border-right:1px solid rgba(0,0,0,0.1);
  min-height: 100vh;
  margin-left:auto;
  margin-right:auto;
  .header {
    border-bottom:1px solid rgba(0,0,0,0.1);
  }
  .main {
    display: flex;
    .left-sidebar {
      width:320px;
      border-right:1px solid rgba(0,0,0,0.1);
      min-height: 100vh;
      padding:1em;
      .search {
        font-size:36px;
        margin-bottom:0.25em;
      }
      .p {
        font-size:18px;
        margin-bottom:0.25em;
      }
    }
    .content {
      flex:1;
      padding-left:1em;
      padding-right:1em;
      p {
        margin-top:0;
        margin-bottom:0.25em;
      }
    }
  }
}

textarea {
  width:100%;
  min-height: 260px;
  padding:0.5em;
  box-sizing: border-box;
  background: rgba(yellow,0.1);
  border-radius:0.5em;
  border:0px;
  overflow: hidden;
  margin-top:2em;
  border:1px solid #ccc;
  &:hover {

  }
}

.reload {
  width:100%;
  background: #666;
  padding:0.5em;
  color:white;
  font-size:18px;
  border:none;
  cursor: pointer;
  box-sizing: border-box;
  &:hover {
    background: #333;
  }
}

.reset {
  margin-top:0.25em;
  background: #e1e1e1;
  padding:0.5em 1em;
  border:none;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  box-sizing: border-box;
  &:hover {
    background: #ccc;
  }
}

h2 {
  position: relative;
}
.edit {
  font-size:14px;
  color:#aaa;
  font-weight: 500;
  padding:0.33em 0.66em;
  cursor:pointer;
  &:hover {
    color:#009fa1;
    background: rgba(0,0,0,0.1);
  }
}

.h3 {
  background: #ee0000;
  color:white;
  padding:0.5em 1em;;
  border-radius: 1em;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  max-width: 480px;
  line-height: 1;
  margin-bottom:0.5em;
}
hr {
  margin:15px;
  background: none;
  border:0;
}
.h4 {
  color:white;
  padding:0.5em 1em;;
  border-radius: 1em;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  max-width: 480px;
  line-height: 1;
  margin-bottom:0.5em;
  background: #14426b;
  font-size: 1.17em;
}
.ul {
  li {
    margin-bottom:0.25em;
    max-width: 480px;
  }
}

.placeholder {
  height:1em;
  background: rgba(0,0,0,0.1);
  &.width-66 {
    max-width: 66%;
  }
  &.width-50 {
    max-width: 50%;
  }
  &.width-33 {
    max-width: 33%;
  }
}